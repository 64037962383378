<template>
    <v-card>
      <v-card-subtitle right>
        <v-btn small text :outlined="scope === 'all'" @click="(scope = 'all')">
          <span caption text-lowercase>Alle</span>
        </v-btn>
        <v-btn small text :outlined="scope === 'Krankenkasse'" @click="(scope = 'Krankenkasse')">
          <span caption text-lowercase>Krankenkasse</span>
        </v-btn>
        <v-btn
          small
          text
          :outlined="scope === 'Others'"
          @click="(scope = 'others')"
        >
          <span caption text-lowercase>andere</span>
        </v-btn>
      </v-card-subtitle>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suchen"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>
 <v-data-table 
        dense
        :headers="headers"
        :items="pricelists"
        :search="search"
        :items-per-page="20"
        class="elevation-1"
        @click:row="rowSelected"
      ></v-data-table>
    </v-card>


</template>

<script>
export default {
  data () {
    return {
        scope: 'all',
        headers: [
        {
          text: "Tarifgruppe",
          align: "start",
          sortable: true,
          value: "tarifName",
        },
        {
          text: "gültig ab",
          align: "start",
          sortable: true,
          value: "validFrom",
        },
        {
          text: "bei Wechsel",
          align: "start",
          sortable: true,
          value: "changeRule",
        },
      ],
      pricelists: [],
    }
  },
  components: {
    
  },
  methods: {
    rowSelected(row) {
    const id = row._id;
    const name = row.tarifName + " vom " + row.validFrom
    this.$router.push({ name: "Preisliste", params: { id, name } });
  },
  },
  async mounted() {
    console.log("Fetch pricelists from backend");
    const response = await this.$http.get("pricelist/");
    this.pricelists = response.data;
    this.loading = false;
  },
}
</script>

<style>

</style>