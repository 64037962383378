<template>
  <div class="home">
    
    <DocuItem
      v-for="item in unsavedDocuItems"
      :key="item.id"
      :item="item"
      color="green"
    ></DocuItem>
    <template v-if="docuItems.length == 0">
    Keine Doku vorhanden
    </template>
    <DocuItem
      v-for="item in docuItems"
      :key="item.id"
      :item="item"
      :color="color"
    ></DocuItem>
  </div>
</template>


<script>
//import AvailabilityContainer from "../components/AvailabilityContainer";
//import Availability from "../components/availability";
import DocuItem from "../components/DocuItem";
//import NewWaitListItem from "../components/NewWaitListItem";
export default {
  data() {
    return {
      //Anamnese, Diagnostik / Befund, Diagnose, Therapie, Therapieziel
      docuItems: []
    };
  },
  props: {
    queryDocu:  {
      default: true,
    },
    patient: {
      default: null,
    },
    unsavedDocuItems: null,
    color: {
      default: "blue-grey lighten-5",
    },
    /*docuItems: {
      default() {
        return [
          {
            id: "dsafsd",
            date: "14.04.2022",
            weekday: "Mittwoch",
            lastName: "Mustermann",
            docuElements: [
              {
                _id: "1341241",
                category: "Therapie",
                content:
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem aperiam id velit deserunt, voluptates corporis numquam modi eaque, doloribus repudiandae nam dolore fuga ipsa eum ex quos illo maxime! Nemo!",
              },
              {
                _id: "1341wd241",
                category: "Therapieziel",
                content:
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem aperiam id velit deserunt, voluptates corporis numquam modi eaque, doloribus repudiandae nam dolore fuga ipsa eum ex quos illo maxime! Nemo!",
              },
            ],
          },
          {
            id: "asdfff",
            date: "10.04.2022",
            weekday: "Montag",
            docuElements: [
              {
                _id: "1341241",
                category: "Therapie",
                content:
                  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem aperiam id velit deserunt, voluptates corporis numquam modi eaque, doloribus repudiandae nam dolore fuga ipsa eum ex quos illo maxime! Nemo!",
              },
            ],
          },
          {
            id: "asdfgf",
            date: "28.03.2022",
            weekday: "Dienstag",
            firstName: "Pippi",
            lastName: "Langstrumpf",
            availability: ["Do09:00", "Do10:00", "Do11:00"],
          },
        ];
      },
    },*/
  },
  components: {
    //AvailabilityContainer,
    //Availability,
    DocuItem,
    //NewWaitListItem,
  },
    async mounted() {
    console.log("Fetch docu from backend");
    var params = {}
    if (this.patient) {
      console.log("with patient");
      params = {params: { patient: this.patient._id }}
    }  
    
      const response = await this.$http.get("docu/", params);
    

    
    this.docuItems = response.data;
    this.loading = false;
  },
  watch: {
    patient: {
      async handler(val) {
        console.log("Fetch docu from backend");
      var params = {}
      if (this.patient) {
        console.log("with patient");
        params = {params: { patient: this.patient._id }}
      }  
      
        const response = await this.$http.get("docu/", params);
      

      
      this.docuItems = response.data;
      this.loading = false;
      },
    },
  },
};
</script>

