<template>
  <div id="mainwindow" v-if="loggedIn">
    <v-app class="mb-4">
      <Navbar />
      <v-main>
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>

      <Footer />
    </v-app>
  </div>

  <div v-else>
    <v-app>
<NavbarRegister />
      <v-main>
        <v-container fluid>
          <LoginForm></LoginForm>
        </v-container>
      </v-main>

      
      
    </v-app>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import NavbarRegister from "@/components/NavbarRegister"
import LoginForm from "@/components/LoginForm";
//import BreadCrumb from "@/components/BreadCrumb";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    LoginForm,
    NavbarRegister,
    //BreadCrumb,
    Navbar,
    Footer,
  },
  data: () => ({
    //
  }),
  computed: {
    loggedIn: function () {
      return this.$store.state.user.status.loggedIn;
    },
    userStored: function () {
      return this.$store.state.user.status.loaded;
    },
  },
  methods: {
    loginUser: function () {
      this.$store.dispatch("user/loginAction");
    },
  },
};
</script>
