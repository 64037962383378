<template>
    <v-card>
      <v-card-subtitle right>
        <v-btn small text :outlined="scope === 'all'" @click="(scope = 'all')">
          <span caption text-lowercase>Alle</span>
        </v-btn>
        <v-btn small text :outlined="scope === 'Krankenkasse'" @click="(scope = 'Krankenkasse')">
          <span caption text-lowercase>Krankenkasse</span>
        </v-btn>
        <v-btn
          small
          text
          :outlined="scope === 'Others'"
          @click="(scope = 'others')"
        >
          <span caption text-lowercase>andere</span>
        </v-btn>
      </v-card-subtitle>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suchen"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>
 <v-data-table 
        dense
        :headers="headers"
        :items="items"
        :search="search"
        :items-per-page="20"
        class="elevation-1"
        @click:row="rowSelected"
      >
        <template v-slot:[`item.price`]="{ item }">
        {{ (item.price / 100) }}
        </template>

        <template v-slot:[`item.additionalPayment`]="{ item }">
        {{ (item.additionalPayment / 100) }}
        </template>
      </v-data-table>
    </v-card>


</template>

<script>
export default {
  data () {
    return {
        loading:true,
        scope: 'all',
        headers: [
        {
          text: "Positionsnummer",
          align: "start",
          sortable: true,
          value: "service.posNr",
        },
        {
          text: "Kürzel",
          align: "start",
          sortable: true,
          value: "service.shortcut",
        },
        {
          text: "Leistung",
          align: "start",
          sortable: true,
          value: "service.description",
        },
        {
          text: "Preis",
          align: "start",
          sortable: true,
          value: "price",
        },
        {
          text: "Zuzahlung",
          align: "start",
          sortable: true,
          value: "additionalPayment",
        },
      ],
      items: [],
    }
  },
  props: {
    id: 0,
  },
  components: {
    
  },
  async mounted() {
    console.log("Fetch pricelist items from backend");
    const response = await this.$http.get("pricelist/" + this.id + "/items/");
    this.items = response.data;
    this.loading = false;
  },
}
</script>

<style>

</style>