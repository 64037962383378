<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn class="success" tile v-on="on">Neuer Patient</v-btn>
    </template>
    <v-card>
      <v-card-title>Patient anlegen</v-card-title>
      <v-card-text>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Stammdaten
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Kontaktdaten
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Krankenkasse </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Verfügbarkeit </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12">
                <v-card-title>Stammdaten</v-card-title>
                <v-card-text>
                  <form>
                    <v-text-field
                      v-model="lastName"
                      :error-messages="lastNameErrors"
                      :counter="10"
                      label="Nachname"
                      required
                      @input="$v.lastName.$touch()"
                      @blur="$v.lastName.$touch()"
                    ></v-text-field>
                    <v-text-field
                      v-model="firstName"
                      :error-messages="firstNameErrors"
                      :counter="10"
                      label="Vorname"
                      required
                      @input="$v.firstName.$touch()"
                      @blur="$v.firstName.$touch()"
                    ></v-text-field>
                    <v-text-field
                      v-model="dob"
                      label="Geburtstag"
                      type="date"
                      required
                      @input="$v.dob.$touch()"
                      @blur="$v.dob.$touch()"
                    ></v-text-field>
                    <v-select
                      v-model="gender"
                      :items="genderList"
                      label="Geschlecht"
                      required
                      @change="$v.gender.$touch()"
                      @blur="$v.gender.$touch()"
                    ></v-select>

                    <v-btn class="mr-4" outlined tile @click="submitMasterdata">
                      Weiter
                    </v-btn>
                    <v-btn @click="clear" outlined tile> clear </v-btn>
                  </form>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card
                class="mb-12"
                color="grey lighten-1"
                height="200px"
              ></v-card>

              <v-btn color="primary" @click="e1 = 3"> Continue </v-btn>

              <v-btn text> Cancel </v-btn>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card
                class="mb-12"
                color="grey lighten-1"
                height="200px"
              ></v-card>

              <v-btn color="primary" @click="e1 = 1"> Continue </v-btn>

              <v-btn text> Cancel </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <!--<form>
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            :counter="10"
            label="Nachname"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            :counter="10"
            label="Vorname"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="E-mail"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>
          <v-select
            v-model="select"
            :items="items"
            :error-messages="selectErrors"
            label="Item"
            required
            @change="$v.select.$touch()"
            @blur="$v.select.$touch()"
          ></v-select>
          <v-checkbox
            v-model="checkbox"
            :error-messages="checkboxErrors"
            label="Do you agree?"
            required
            @change="$v.checkbox.$touch()"
            @blur="$v.checkbox.$touch()"
          ></v-checkbox>

          <v-btn class="mr-4" @click="submit"> submit </v-btn>
          <v-btn @click="clear"> clear </v-btn>
        </form>
        //-->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    firstName: { required },
    lastName: { required },
    dob: {},
    gender: {},
  },

  data: () => ({
    e1: 1,
    dialog: false,
    firstName: "",
    lastName: "",
    dob: null,
    name: "",
    email: "",
    select: null,
    gender: null,
    genderList: ["weiblich", "männlich", "divers"],
    checkbox: false,
  }),

  computed: {
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required && errors.push("Bitte Nachname angeben");
      return errors;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required && errors.push("Bitte Vorname angeben");
      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
    },
    submitMasterdata() {
      this.$v.$touch();
      if (this.$v.$invalid) {
      } else {
        this.e1 = 2;
      }
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = false;
    },
  },
};
</script>