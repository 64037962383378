<template>
<div>
    <Availability :availabilityList="exampleList"></Availability>
</div>
</template>

<script>
import Availability from './availability.vue'
 
export default {
  components: {
    Availability
  },
  data () {
    return {
      exampleList: ['Mo08:00', 'Mo09:00'] 
    }
  }
}
</script>
