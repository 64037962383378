import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import WaitList from '../views/WaitList'
import PatientList from '../views/PatientList'
import Calendar from '../views/Calendar'
import Patient from '../views/Patient'
import Prescription from '../views/Prescription'
import Prescriptions from '../views/Prescriptions'
import PricelistList from '../views/PricelistList'
import Pricelist from '../views/Pricelist'
import Userlist from '../views/Userlist'
import VTiptap from "@peepi/vuetify-tiptap";

Vue.use(VTiptap);


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      breadCrumb: [
        {
          text: 'Übersicht'
        }
      ]
    }
  },
  {
    path: '/waitlist',
    name: 'WaitList',
    component: WaitList,
            meta: {
      breadCrumb: [

          {
            text: 'Übersicht',
            to: { name: 'Home' }
          },
          {
            text: "Warteliste",
          }

        ]
    }
  },
    {
    path: '/user',
    name: 'Mitarbeiter',
    component: Userlist,
            meta: {
      breadCrumb: [

          {
            text: 'Übersicht',
            to: { name: 'Home' }
          },
          {
            text: "Mitarbeiter",
          }

        ]
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
        meta: {
      breadCrumb: [

          {
            text: 'Übersicht',
            to: { name: 'Home' }
          },
          {
            text: "Kalender",
          }

        ]
    }
  },
  {
    path: '/pricelist',
    name: 'Preislisten',
    component: PricelistList,
        meta: {
      breadCrumb: [

          {
            text: 'Übersicht',
            to: { name: 'Home' }
          },
          {
            text: "Preislisten",
          }

        ]
    }
  },
  {
    path: '/pricelists/:id/:name',
    name: 'Preisliste',
    component: Pricelist,
    props: true,
        meta: {
      breadCrumb(route) {
            const name = route.params.name
    return [
      {
        text: 'Übersicht',
        to: { name: 'Home' }
      },
      {
        text: "Preislisten",
        to: { name: 'Preislisten' }
      },
      {
        text: name,
      }
    ]
  }
    }
  },
  {
    path: '/patient',
    name: 'PatientList',
    component: PatientList,
        meta: {
      breadCrumb: [

          {
            text: 'Übersicht',
            to: { name: 'Home' }
          },
          {
            text: "Patienten",
          }

        ]
    }
  },
  {
    path: '/patient/:id/:name', 
    name: "Patient",
    component: Patient,
    props: true,
            meta: {
      breadCrumb(route) {
                const patientId = route.params.id;
                const name = route.params.name
        return [
          {
            text: 'Übersicht',
            to: { name: 'Home' }
          },
          {
            text: "Patienten",
            to: { name: 'PatientList' }
          },
          {
            text: name,
          }
        ]
      }
    }
  },
  {
    path: '/prescription',
    name: 'Verordnungen',
    component: Prescriptions,
        meta: {
      breadCrumb: [

          {
            text: 'Übersicht',
            to: { name: 'Home' }
          },
          {
            text: "Verordnungen",
          }

        ]
    }
  },
  {
    path: '/prescription/:id/:patId?/:patName?', 
    name: "Verordnung",
    component: Prescription,
    props: true,
            meta: {
      breadCrumb(route) {
                //const patientId = route.params.id;
                const patName = route.params.patName
                const patId = route.params.patId;
        const breadcrumbs = [{
          text: 'Übersicht',
          to: { name: 'Home' }
        }]
        
        if (patId) {
          const id = patId;
          const name = patName;
          breadcrumbs.push({
            text: "Patienten",
            to: { name: 'PatientList' }
          })
          breadcrumbs.push({
              text: patName,
              to: { name: "Patient", params: { id, name } }
          })
        } else {
            breadcrumbs.push({
                text: "Verordnungen",
                to: { name: 'Verordnungen' }
          })
        }

        breadcrumbs.push({text: 'Verordnung'})
        return breadcrumbs
      }
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
