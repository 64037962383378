<template>
  <div>
    <v-card pa-3 outlined tile :color="color" class="black--text">
      <v-card-title>{{ dateString(item.date) }}</v-card-title>
      <template v-for="contentItem in item.docuElements">
        <v-card-text :key="contentItem._id + 'text'" class="black--text">
          <div class="text-overline">
            {{ contentItem.category }}
          </div>
          <v-tiptap
          :value="contentItem.content"
          view="true"
          locale="de"
        />
        <!--{{contentItem.content}}-->
          <v-spacer></v-spacer
        ></v-card-text>
      </template>
    </v-card>
    <br />
  </div>
</template>

<script>
//Anamnese, Diagnostik / Befund, Diagnose, Therapie, Therapieziel
//import Availability from './availability'
import '@js-joda/timezone'
import { DateTimeFormatter, LocalDateTime } from '@js-joda/core'
import { Locale } from '@js-joda/locale_de' // Get `Locale` from the prebuilt package of your choice
var nativeJs = require("@js-joda/core").nativeJs;




export default {
  props: {
    item: Object,
    color: {},
  },
  computed:{
  
  },
  components: {
    //Availability
  },
  methods: {
    dateString(date) {
      //const d = LocalDateTime.parse('2018-04-28T12:34')
      const d = LocalDateTime.from(nativeJs(new Date(date)));

const formatter = DateTimeFormatter
  .ofPattern('eeee, d.M.yyyy')
  .withLocale(Locale.GERMANY)
      return d.format(formatter)
      //d.format(DateTimeFormatter.ofPattern('HH:mm')) // 12:34
    }
  }
};
</script>