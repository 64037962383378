<template>
  <content>

    <v-card class="pa-2" outlined tile>
          <v-tabs show-arrows color="blue-grey darken-3">
            <v-tab v-for="tab in tabs" :key="tab.tab">
              {{ tab.tab }}
            </v-tab>

            <v-tab-item v-for="tab in tabs" :key="tab.tab">
              <v-card flat>
                <v-card-text>
                  <component
                    v-bind:is="tab.component"
                    :prescription="prescription"
                    :readonly="true"
                  ></component
                ></v-card-text> </v-card
            ></v-tab-item>
          </v-tabs> </v-card>

  </content>
</template>

<script>
import Muster13Front from "../components/Muster13Front.vue"
import Muster13Back from "../components/Muster13Back.vue"
export default {
  data () {
    return {
      prescription: null,
      tabs: [
        { tab: "Vorderseite", component: "Muster13Front" },
        { tab: "Rückseite (Termine)", component: "Muster13Back" },
        { tab: "Sonstiges", component: "" },
      ],
    }
  },
  props: {
    id: 0,
    patientId: 0,
  },
  components: {
    Muster13Front,
    Muster13Back,
  },
  async mounted() {
    console.log("Fetch prescription from backend");
    const response = await this.$http.get("prescription/" + this.id);
    this.prescription = response.data;
    
  },
};
</script>

<style>

</style>