<template>
<table width="85%" class="presc" cellspacing="0">
 <tr>
   <td class="pa-2 light-blue lighten-4"> 
    <table width="100%" class="presc white" cellspacing="0">

      <tr>
        <td class="red--text light-blue lighten-4" colspan="5">
          <div class="headline">
          <b>Empfangsbestätigung durch den Versicherten</b></div>
          Ich bestätige, die im Folgenden aufgeführten Behandlungen erhalten zu haben
        </td>
      </tr>
      <tr>
        <td class="border_red_c align_center red--text" width="15%" colspan="2">Datum</td>
        <td class="border_red_c red--text" width="40%">Maßnahmen</td>
        <td class="border_red_c red--text">Leistungserbringer</td>
        <td class="border_red_c red--text">Unterschrift des Verischerten</td>
      </tr>
      
      <tr v-for="index in 21" :key="index">
        <td class="border_red_c align_center red--text" width="5%">{{index}}</td>
        <td class="border_red align_center" >13.05.2022</td>
        <td class="border_red" width="40%">.</td>
        <td class="border_red">.</td>
        <td class="border_red">.</td>
      </tr>


    </table>
  </td>
</tr>
</table>
 
</template>

<script>

export default {
  props: {
    id: 0,
    patientId: 0,
  },
};
</script>

<style>

th.align_left {
  text-align: left;
}

table.presc {
  padding: 0px;
  border-spacing: 0px;
  margin: 0px;
}

td.padding_t {
  padding-top: 5px;
  padding-bottom: 0px;
}

td.presc {
  
  vertical-align: top;
}

td.align_right {
  
  text-align: right;
}

td.align_center {
  
  text-align: center;
}

td.td _red {
  border: 1px solid red;
  border-collapse: collapse;
}

div.border_red {
  padding-left: 5px;
  border: 1px solid red;
  border-collapse: collapse;
}

td.border_red {
  padding-left: 5px;
  border: 1px solid red;
  border-collapse: collapse;
}

td.border_red_c {
  background-color: #B3E5FC;
  padding-left: 5px;
  border: 1px solid red;
  border-collapse: collapse;
}

td.border_red_tlr {
  vertical-align: top;
  height: 100%;
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-left: 1px solid red;
  border-right: 1px solid red;
  border-collapse: collapse;
}


div.border_red_tlr {
  height: 100%;
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-left: 1px solid red;
  border-right: 1px solid red;
  border-collapse: collapse;
}

div.border_red_tb {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-collapse: collapse;
}

div.border_red_lr {
  padding-left: 5px;
  padding-bottom: 5px;
  border-left: 1px solid red;
  border-right: 1px solid red;
  border-collapse: collapse;
}

td.border_red_tbl {
  vertical-align: top;
  padding-left: 5px;
  
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-left: 1px solid red;
  border-collapse: collapse;

}

td.border_red_b {
  vertical-align: top;
  padding-left: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid red;
  border-collapse: collapse;

}

td.border_red_br {
  vertical-align: top;
  padding-left: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid red;
  border-right: 1px solid red;
  border-collapse: collapse;

}

div.border_red_tbl {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-left: 1px solid red;
  border-collapse: collapse;

}

div.border_red_tr {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-right: 1px solid red;
  border-collapse: collapse;

}

div.border_red_tbr {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-right: 1px solid red;
  border-collapse: collapse;
}

div.border_red_tl_rhalf {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-left: 1px solid red;

  position: relative;
}
div.border_red_tl_rhalf:after {
  position: absolute;
  border-right: 1px solid red;
  height: 40%;
  width: 100%;
  bottom: 0px;
  left: 0;
  content: "";
}

div.border_red_t_rhalf {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;


  position: relative;
}
div.border_red_t_rhalf:after {
  position: absolute;

  border-right: 1px solid red;
  height: 40%;
  width: 100%;
  bottom: 0px;
  left: 0;
  content: "";
}

div.border_red_tbl_rhalf {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-left: 1px solid red;
  position: relative;
}
div.border_red_tbl_rhalf:after {
  position: absolute;

  border-right: 1px solid red;
  height: 40%;
  width: 100%;
  bottom: 0px;
  left: 0;
  content: "";
}

div.border_red_tb_rhalf {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  position: relative;
}
div.border_red_tb_rhalf:after {
  position: absolute;

  border-right: 1px solid red;
  height: 40%;
  width: 100%;
  bottom: 0px;
  left: 0;
  content: "";
}

td.border_green {
  border-bottom: 1px solid green;
  border-top: 1px solid green;
  border-left: 1px solid green;
  border-right: 1px solid green;
  border-collapse: collapse;
}

tr.tr_red {
  border: 1px solid red;
  border-collapse: collapse;
}
</style>