<template>
  <v-dialog v-model="dialog" max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn class="success" v-on="on">Neu</v-btn>
    </template>
    <v-card>
      <v-card-title>Neuer Eintrag in Warteliste</v-card-title>
      <v-card-text>
        <v-form model="isValid">
          <v-text-field
            label="Vorname"
            v-model="firstName"
            :rules="[(v) => !!v || 'Name is required']"
          ></v-text-field>
          <v-text-field label="Nachname" v-model="lastName"></v-text-field>
          <v-textarea label="Kommentar" v-model="comment"></v-textarea>
          <Availability :availabilityList="availability"></Availability>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="save" :disabled="!isValid">Speichern</v-btn>
        <v-btn @click="dialog = false">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Availability from "./availability.vue";
export default {
  data() {
    return {
      dialog: false,
      availability: [],
      firstName: "",
      lastName: "",
      comment: "",
      isValid: true,
    };
  },
  components: {
    Availability,
  },
  mounted() {
    console.log("the component is now mounted.");
  },
  methods: {
    save() {
      console.log("Save waitlistitem");
    },
  },
};
</script>