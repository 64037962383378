<template>
  <div>
      <table border="0" cellspacing="1">
        <tr>
          <td></td>
          <td v-for="timeslot in timeslots" :key="timeslot" class="headline"><div class="caption">{{ timeslot }}</div></td>
        </tr>
        
        <tr v-for="day in days" :key="day">
          <td class="headline"><div class="caption">{{ day }}</div></td>
         <td v-for="timeslot in timeslots" :key="timeslot" v-on="readonly ? {} : {click: $event => toggleCell($event, timeslot, day)}"  :class="available(timeslot, day) ? 'available' : 'not-available'">
          <!--<v-chip @click="toggleCell(timeslot, day)" ma-2 :class="available(timeslot, day) ? 'success' : 'grey'"></v-chip>/-->
        </td>
        <td v-if="!readonly">
          Vormittag / Nachmittag
        </td>
        </tr>
      </table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      //availabilityList: [],//this.availabilityData,
      days: ['Mo', 'Di', 'Mi', 'Do', 'Fr'],
      timeslots: ['08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00']
    }
  },
  props: {
    availabilityList: {
      default: []
    },
    readonly: {
      type: Boolean,
      default: false
    }
  }
  ,
  methods: {
    toggleCell: function (event, timeslot, day) {
      var cellIdentifyer = day+timeslot
      console.log("Cell toggle:" + cellIdentifyer)
      console.log("Event:" + event.button)

        if (this.availabilityList.includes(cellIdentifyer)) {
          const index = this.availabilityList.indexOf(cellIdentifyer);
          if (index > -1) {
          console.log("Remove:" + cellIdentifyer)
          this.availabilityList.splice(index, 1);
          }
        } else {
          console.log("Add:" + cellIdentifyer)
          this.availabilityList.push(cellIdentifyer)
        }
    },
    available(timeslot, day) {
      var cellIdentifyer = day+timeslot
      return this.availabilityList.includes(cellIdentifyer)
    }
  },
  mounted() {
    console.log("the component: availability is now mounted.")
  }
}
</script>

<style>
td.available
{
  background-color:green;
  color:white;
}

td.headline
{
  padding: 5px;
  background-color: #1e90ff;
  color:white;
}

td.not-available
{
  background-color:#bfc1c2;
  color:white;
}

</style>
