<template>
  <v-row>
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Heute
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Tag</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Woche</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'category'">
                <v-list-item-title>Gruppiert</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>

      <v-sheet>
        <v-combobox
          v-model="chips"
          :items="items"
          chips
          clearable
          label="Mitarbeiter"
          multiple
          prepend-icon="mdi-account"
          solo
          dense
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              :color="calendarColor(item)"
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="remove('person', item)"
            >
              <strong>{{ item }}</strong
              >&nbsp;
            </v-chip>
          </template>
        </v-combobox>
        <v-spacer></v-spacer>
      </v-sheet>

      <v-sheet v-if="this.type == 'category'">
        <v-combobox
          v-model="chipsResource"
          :items="itemsResource"
          chips
          clearable
          label="Ressourcen"
          multiple
          prepend-icon="mdi-door-sliding"
          solo
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="remove('resource', item)"
            >
              <strong>{{ item }}</strong
              >&nbsp;
            </v-chip>
          </template>
        </v-combobox>
        <v-spacer></v-spacer>
      </v-sheet>
      <v-sheet height="55%">
        <v-calendar
          class="mycalendar"
          ref="calendar"
          :event-color="calendarEventColor"
          v-model="focus"
          :events="appointments"
          :interval-minutes="intervalMinutes"
          event-name="description"
          :categories="categories"
          :type="type"
          :weekdays="weekday"
          category-show-all
          :first-interval="firstTime"
          :interval-count="numIntervals"
          locale="de"
          @click:event="showEvent"
        >
          <template v-slot:day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            ></div>
          </template>

          <template v-slot:event="{ event }">
            <div :class="eventBorder(event.status)" black--text>
              <table width="100%" height="100%">
                <tr>
                  <td class="top">
                    <strong
                      ><v-icon small color="black" v-if="event.complete"
                        >mdi-image-filter-center-focus-strong</v-icon
                      >
                      {{ event.description }}</strong
                    >
                  </td>
                </tr>
                <!--<tr><td class="top"><strong>{{event.description}}</strong></td><td class="top_right"><v-icon small>mdi-home</v-icon></td></tr>-->
                <!--<tr><td class="top"><strong>{{event.description}}</strong></td><td class="top_right"><v-icon small>mdi-home-city</v-icon></td></tr>-->
                <tr>
                  <td class="top">
                    <v-icon small>mdi-clock-outline</v-icon>9:00
                    <v-icon small>mdi-door-sliding</v-icon> Dinohöhle
                  </td>
                </tr>

                <tr height="100%">
                  <td class="bottom">6/10</td>
                </tr>
              </table>
            </div>
          </template></v-calendar
        >

        <NewDocuDialog
          :show.sync="newDocuDialog"
          :appointment="selectedEvent"
        ></NewDocuDialog>

        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
          tile
        >
          <v-card color="grey lighten-4" tile>
            <v-card-subtitle
              :color="eventColor(selectedEvent.status)"
              dense
              flat
            >
              {{ selectedEvent.description }}

              <v-spacer></v-spacer>
            </v-card-subtitle>

            <v-card-actions>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="black"
                    @click="
                      selectedOpen = false;
                      newDocuDialog = true;
                    "
                    @update="newDocuDialog = $event"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-folder-multiple-plus</v-icon>
                  </v-btn>
                </template>
                <span>Doku</span>
              </v-tooltip>

              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="black"
                    @click="selectedOpen = false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-swap-horizontal</v-icon>
                  </v-btn>
                </template>
                <span>Status ändern</span>
              </v-tooltip>

              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="black"
                    @click="selectedOpen = false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Bearbeiten</span>
              </v-tooltip>

              <v-tooltip bottom color="red">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="black"
                    @click="selectedOpen = false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Löschen!</span>
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import NewDocuDialog from "../components/NewDocuDialog";
export default {
  data: () => ({
    calendarColors: ["blue-grey lighten-3", "teal lighten-3", "orange lighten-3", "light-green lighten-3"],
    newDocuDialog: false,
    value: "",
    ready: false,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    firstTime: 32,
    numIntervals: 48,
    intervalMinutes: 15,
    appointments: [],
    chips: [],
    items: [],
    chipsResource: [],
    itemsResource: [],
    focus: "",
    type: "week",
    typeToLabel: {
      month: "Monat",
      week: "Woche",
      day: "Tag",
      category: "Gruppiert",
    },
    today: "2022-02-22",
    weekday: [1, 2, 3, 4, 5],
    events: [
      {
        name: "Weekly Meeting",
        start: "2022-02-22 09:00",
        end: "2022-02-22 10:00",
        color: "red",
        calendar: "",
      },
      {
        name: `Thomas' Birthday`,
        start: "2022-02-22",
      },
      {
        name: "Mash Potatoes",
        start: "2022-02-22 12:30",
        end: "2022-02-22 15:30",
      },
    ],
  }),
  computed: {
    ...mapGetters("user", {
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      getUserName: "getUserName",
    }),
    ...mapGetters('users', ['getUsersDisplayName']),
    categories() {
      return [...this.chips, ...this.chipsResource];
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    calendars() {
      var cal = [];
      console.log("Calculating calendars");
      this.chips.forEach((element) => {
        console.log("Chip: " + element);
        if (element == "Mai, Kathrin") cal.push("KM");
        if (element == "Mai, Thorsten") cal.push("TM");
        if (element == "Bachmann, Katharina") cal.push("KB");
        if (element == "Schöffler, Eva") cal.push("ES");
      });
      console.log("cal: " + cal);
      return cal;
    },
  },
  methods: {
    calendarColor(item) {
      console.log("Item: " + item);
      const index = this.chips.indexOf(item);
      return this.calendarColors[index];
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    setToday() {
      this.focus = "";
    },
    calendarEventColor(evt) {
      //this.$store.dispatch('increment')
      const displayName = this.getUsersDisplayName(evt.user.shortcut)
      console.log("display name (cal)" + displayName)
      const index = this.chips.indexOf(displayName);
      return this.calendarColors[index];
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    remove(scope, item) {
      if (scope == "person") {
        this.chips.splice(this.chips.indexOf(item), 1);
        this.chips = [...this.chips];
        console.log("Remove Person " + item);

        if (this.chips.length == 0) {
          this.chips.push(this.getUserName);
        }
      } else if (scope == "resource") {
        console.log("Remove resource" + item);
        this.chipsResource.splice(this.chipsResource.indexOf(item), 1);
        this.chipsResource = [...this.chipsResource];
      }
    },
    eventBorder(status) {
      console.log("status: " + status);
      if (status == "occurred") {
        return "occurred black--text";
      } else if (status == "cancelled") {
        return "cancelled black--text";
      }
      return "planned black--text";
    },
    eventColor(status) {
      console.log("status: " + status);
      if (status == "occurred") {
        return "#388e3c";
      } else if (status == "cancelled") {
        return "#ef5350";
      }
      return "#81d4fa";
    },
  },
  async mounted() {
    this.chips.push(this.getUserName);
    this.items.push(this.getUserName);
    this.items.push("Mai, Kathrin");
    this.items.push("Bachmann, Katharina");
    this.items.push("Schöffler, Eva");

    this.itemsResource.push("Märchenburg");
    this.itemsResource.push("Dinohöhle");
    this.itemsResource.push("Zauberwald");
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },
  components: {
    NewDocuDialog,
  },
  watch: {
    chips: {
      async handler(val) {
        console.log("Fetch appointments from backend for " + this.calendars);
        const response = await this.$http.get("appointment/", {
          params: { calendars: this.calendars },
        });
        this.appointments = response.data;
        console.log(this.appointments);

        this.appointments.forEach((element) => {
          element.category = "Dinohöhle";
          if (
            element.treatment.length == 1 &&
            element.treatment[0].prescription &&
            element.treatment[0].service
          ) {
            element.complete = true;
            console.log("enhancing the appointment");
            element.description =
              element.treatment[0].service.shortcut +
              ": " +
              element.treatment[0].prescription.patient.lastName +
              ", " +
              element.treatment[0].prescription.patient.firstName;
          }
        });
      },
    },
  },
};
</script>

<style>
td.top {
  vertical-align: top;
}

td.bottom {
  vertical-align: bottom;
  text-align: right;
}

.v-event-timed {
  border-radius: 0px !important;
}

.my-event.with-time {
  background-color: green;
  position: absolute;
  right: 6px;
  margin-right: 0px;
}

.cancelled {
  border-left: 10px solid #ef5350 !important;
  height: 100%;
}

.planned {
  border-left: 10px solid #81d4fa !important;
  height: 100%;
}

.occurred {
  border-left: 10px solid #388e3c !important;
  height: 100%;
}

.v-current-time {
  height: 1px;
  background-color: #ff7043;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
}
</style>


