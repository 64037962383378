import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueMixpanelBrowser from 'vue-mixpanel-browser';
import config from './config/client.config'


Vue.use(VueMixpanelBrowser, {
  token: 'ca7ff502525880cb3a8b475b67a40616',
  config: {
    debug: true
  }
});



import axios from 'axios'

const base = axios.create({
  baseURL: config.api.url + '/api'
});

Vue.prototype.$http = base; 
Vue.config.productionTip = false

 Vue.prototype.$http.interceptors.request.use(
  config => {
      //let user = JSON.parse(localStorage.getItem('user'));
      let token = localStorage.getItem('userToken');


      //console.log(accessToken)
      if (token) {
        console.log("token")
        console.log(token)

          config.headers["x-access-token"] = token;
      }
      return config;
  },
  error => {
      return Promise.reject(error);
  }
);


Vue.prototype.$http.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/login" && err.response) {
      // Access Token was expired
      console.log("refresh expired access token")
      console.log(err.response)
      console.log("Refresh Token: " + localStorage.getItem('refreshToken'))

      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await Vue.prototype.$http.post("/auth/refreshtoken", {
            refreshToken: localStorage.getItem('refreshToken'),
          });
          const { accessToken } = rs.data;
          //store.dispatch('auth/refreshToken', accessToken);
          localStorage.setItem('userToken', accessToken);
          return Vue.prototype.$http(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);





new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
