var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Heute ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Tag")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Woche")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'category'}}},[_c('v-list-item-title',[_vm._v("Gruppiert")])],1)],1)],1)],1)],1),_c('v-sheet',[_c('v-combobox',{attrs:{"items":_vm.items,"chips":"","clearable":"","label":"Mitarbeiter","multiple":"","prepend-icon":"mdi-account","solo":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"color":_vm.calendarColor(item),"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove('person', item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}]),model:{value:(_vm.chips),callback:function ($$v) {_vm.chips=$$v},expression:"chips"}}),_c('v-spacer')],1),(this.type == 'category')?_c('v-sheet',[_c('v-combobox',{attrs:{"items":_vm.itemsResource,"chips":"","clearable":"","label":"Ressourcen","multiple":"","prepend-icon":"mdi-door-sliding","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove('resource', item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}],null,false,2751590482),model:{value:(_vm.chipsResource),callback:function ($$v) {_vm.chipsResource=$$v},expression:"chipsResource"}}),_c('v-spacer')],1):_vm._e(),_c('v-sheet',{attrs:{"height":"55%"}},[_c('v-calendar',{ref:"calendar",staticClass:"mycalendar",attrs:{"event-color":_vm.calendarEventColor,"events":_vm.appointments,"interval-minutes":_vm.intervalMinutes,"event-name":"description","categories":_vm.categories,"type":_vm.type,"weekdays":_vm.weekday,"category-show-all":"","first-interval":_vm.firstTime,"interval-count":_vm.numIntervals,"locale":"de"},on:{"click:event":_vm.showEvent},scopedSlots:_vm._u([{key:"day-body",fn:function(ref){
var date = ref.date;
var week = ref.week;
return [_c('div',{staticClass:"v-current-time",class:{ first: date === week[0].date },style:({ top: _vm.nowY })})]}},{key:"event",fn:function(ref){
var event = ref.event;
return [_c('div',{class:_vm.eventBorder(event.status),attrs:{"black--text":""}},[_c('table',{attrs:{"width":"100%","height":"100%"}},[_c('tr',[_c('td',{staticClass:"top"},[_c('strong',[(event.complete)?_c('v-icon',{attrs:{"small":"","color":"black"}},[_vm._v("mdi-image-filter-center-focus-strong")]):_vm._e(),_vm._v(" "+_vm._s(event.description))],1)])]),_c('tr',[_c('td',{staticClass:"top"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clock-outline")]),_vm._v("9:00 "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-door-sliding")]),_vm._v(" Dinohöhle ")],1)]),_c('tr',{attrs:{"height":"100%"}},[_c('td',{staticClass:"bottom"},[_vm._v("6/10")])])])])]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('NewDocuDialog',{attrs:{"show":_vm.newDocuDialog,"appointment":_vm.selectedEvent},on:{"update:show":function($event){_vm.newDocuDialog=$event}}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":"","tile":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","tile":""}},[_c('v-card-subtitle',{attrs:{"color":_vm.eventColor(_vm.selectedEvent.status),"dense":"","flat":""}},[_vm._v(" "+_vm._s(_vm.selectedEvent.description)+" "),_c('v-spacer')],1),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black"},on:{"click":function($event){_vm.selectedOpen = false;
                    _vm.newDocuDialog = true;},"update":function($event){_vm.newDocuDialog = $event}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-folder-multiple-plus")])],1)]}}])},[_c('span',[_vm._v("Doku")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black"},on:{"click":function($event){_vm.selectedOpen = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1)]}}])},[_c('span',[_vm._v("Status ändern")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black"},on:{"click":function($event){_vm.selectedOpen = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Bearbeiten")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"black"},on:{"click":function($event){_vm.selectedOpen = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}])},[_c('span',[_vm._v("Löschen!")])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }