<template>
  <div class="home">
    <NewWaitListItem></NewWaitListItem>

    <WaitListItem
      v-for="item in waitList"
      :key="item.id"
      :item="item"
    ></WaitListItem>
  </div>
</template>


<script>
import AvailabilityContainer from "../components/AvailabilityContainer";
import Availability from "../components/availability";
import WaitListItem from "../components/WaitListItem";
import NewWaitListItem from "../components/NewWaitListItem";
export default {
  data() {
    return {
      waitList: [
        {
          id: "dsafsd",
          firstName: "Max",
          lastName: "Mustermann",
          availability: ["Mo08:00", "Mo09:00"],
        },
        {
          id: "asdfff",
          firstName: "Ronja",
          lastName: "Räubertochter",
          availability: ["Di09:00", "Mi10:00"],
        },
        {
          id: "asdfgf",
          firstName: "Pippi",
          lastName: "Langstrumpf",
          availability: ["Do09:00", "Do10:00", "Do11:00"],
        },
      ],
      exampleList: ["Mo08:00", "Mo09:00"],
    };
  },
  components: {
    AvailabilityContainer,
    Availability,
    WaitListItem,
    NewWaitListItem,
  },
};
</script>
