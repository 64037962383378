


export default {
  namespaced: true,
  state: {
    loaded: false,
    users: [
      {
      first_name: "Thorsten",
      last_name: "Mai",
      display_name: "Mai, Thorsten",
      shortcut: "TM"
    },
    {
      first_name: "Kathrin",
      last_name: "Mai",
      display_name: "Mai, Kathrin",
      shortcut: "KM"
    },
    {
      first_name: "Katharina",
      last_name: "Bachmann",
      display_name: "Bachmann, Katharina",
      shortcut: "KB"
    },
    {
      first_name: "Eva",
      last_name: "Schöffler",
      display_name: "Schöffler, Eva",
      shortcut: "ES"
    }
  ]
    
  },

  getters: {
    getUsersDisplayName: (state) => (shortcut) => {
      console.log("Shortcut: " + shortcut)
      const user = state.users.find( user => user.shortcut == shortcut)
      console.log("User: " + user)
      console.log("display_name: " + user.display_name)

      return user.display_name 
    }
    
  },
  mutations: {
    
  },
  actions: {
    getUsersShortcut (context, displayName) {
      const user = context.state.users.find( user => user.displayName == displayName)
      return user.shortcut 
    },
    getUsersDisplayName(context, shortcut) {
      console.log("Shortcut: " + shortcut)
      const user = context.state.users.find( user => user.shortcut == shortcut)
      console.log("User: " + user)
      console.log("display_name: " + user.display_name)

      return user.display_name 
    }
  }
}
