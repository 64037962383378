<template>
  <v-card class="pa-2" outlined tile height="100%">
    <v-card-title>{{ patient.firstName }} {{ patient.lastName }} </v-card-title>
    <v-card-subtitle>Stammdaten</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col>
          <v-list two-line dense>
            <v-subheader>Kontaktinformationen</v-subheader>

            <v-list-item
              v-if="
                patient.phoneNumbers &&
                !patient.phoneNumbers.length &&
                patient.mail &&
                !patient.mail.length
              "
            >
              <v-list-item-icon>
                <v-icon color="red"> mdi-alert-octagon-outline </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> Keine Kontaktdaten</v-list-item-title>
                <v-list-item-subtitle>Bitte erfassen!</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              inset
              v-if="
                patient.phoneNumbers &&
                !patient.phoneNumbers.length &&
                patient.mail &&
                !patient.mail.length
              "
            ></v-divider>

            <v-list-item
              v-if="patient.phoneNumbers && !patient.phoneNumbers.length"
            >
              <v-list-item-icon>
                <v-icon color="red"> mdi-phone </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Keine Telefonnummer</v-list-item-title>
                <v-list-item-subtitle>Bitte erfassen!</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-for="(number, index) in patient.phoneNumbers"
              :key="number._id"
            >
              <v-list-item-icon>
                <v-icon color="blue-grey darken-2" v-if="index == 0">
                  mdi-phone
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ number.number }}</v-list-item-title>
                <v-list-item-subtitle>{{ number.type }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item v-if="patient.mail && !patient.mail.length">
              <v-list-item-icon>
                <v-icon color="red"> mdi-email </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Keine Email Adresse</v-list-item-title>
                <v-list-item-subtitle>Bitte erfassen!</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-for="(email, index) in patient.mail"
              :key="email._id"
            >
              <v-list-item-icon v-if="index == 0">
                <v-icon color="blue-grey darken-2"> mdi-email </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ email.mail }}</v-list-item-title>
                <v-list-item-subtitle>{{ email.type }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>
          </v-list>
        </v-col>
        <v-col
          ><v-list two-line dense>
            <v-subheader>Allgemein</v-subheader>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="blue-grey darken-2"> mdi-cake-variant </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <div v-if="patient.dateOfBirth">
                    {{ patient.dateOfBirth }}
                  </div>
                  <div v-else>Geburtsdatum</div></v-list-item-title
                >
                <v-list-item-subtitle
                  ><div v-if="patient.dateOfBirth">
                    {{ this.getAge("01/01/1984") }} Jahre
                  </div>
                  <div v-else>Nicht angegeben</div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="blue-grey darken-2">
                  mdi-gender-transgender
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>männlich</v-list-item-title>
                <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="blue-grey darken-2"> mdi-map-marker </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Hauptstr. 78</v-list-item-title>
                <v-list-item-subtitle>76275 Ettlingen</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list></v-col
        >
        <v-col
          ><v-list two-line dense>
            <v-subheader>Krankenkasse</v-subheader>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="blue-grey darken-2">
                  mdi-order-bool-descending
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Gesetzlich</v-list-item-title>
                <v-list-item-subtitle
                  >AOK Baden-Württemberg</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="blue-grey darken-2">mdi-cash-multiple </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Zuzahlung</v-list-item-title>
                <v-list-item-subtitle>nicht befreit</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider inset></v-divider> </v-list
        ></v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    patient: Object,
  },
  methods: {
    getAge(DOB) {
      var today = new Date();
      var birthDate = new Date(DOB);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
      }
      return age;
    },
  },
};
</script>
