<template>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">

        <v-btn
          
          plain
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="black" left>mdi-view-grid-plus</v-icon>Registrieren
        </v-btn>
      </template>
      <v-card>
       <v-card-title>Jetzt kostenlos registrieren!</v-card-title>
       <v-card-text>

        <v-form model="isValid">
          <v-text-field
            v-model="tenantName"
            label="Praxisname"
            
            required
          ></v-text-field>

          <v-select
            v-model="type"
            :items="types"
            label="Praxistyp"
            dense
          ></v-select>
          


          <v-text-field
            v-model="email"
            label="E-Mail Addresse"
            
            required
          ></v-text-field>

         <v-text-field
            v-model="firstName"
            label="Vorname"
            
            required
          ></v-text-field>

                   

        <v-text-field
          v-model="lastName"
          label="Nachname"
          
          required
        ></v-text-field>

        <v-text-field
            v-model="adminPassword"
            label="Admin-Passwort"
            color="red"
            background-color="red lighten-4"
            required
          ></v-text-field>

        <v-checkbox
              v-model="agb"
              label="Ich habe die AGB gelesen und erkenne diese an"
              
            ></v-checkbox>

      </v-form>
       </v-card-text>
        
               <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="save" :disabled="!isValid" tile outlined
          ><v-icon left>mdi-view-grid-plus</v-icon> Registrieren</v-btn
        >
        <v-btn @click="dialog = false" tile outlined
          ><v-icon left>mdi-close-circle-outline</v-icon>Abbrechen</v-btn
        >
      </v-card-actions>
          
      </v-card>
    </v-dialog>
</template>

<script>
  export default {
    data () {
      return {
        type: '',
        isValid: true,
        types: ['Logopädie', 'Physiotherapie'],
        tenantName: '',
        firstName: '',
        lastName: '',
        email: '',
        agb: false,
        dialog: false,
        
      }
    },
  }
</script>