 <template>
  <div class="home">
    <v-card>
      <v-card-subtitle right>
        <v-btn small text :outlined="scope === 'all'" @click="setScope('all')">
          <span caption text-lowercase>Alle</span>
        </v-btn>
        <v-btn
          small
          text
          :outlined="scope === 'activeOnly'"
          @click="setScope('activeOnly')"
        >
          <span caption text-lowercase>Aktiv</span>
        </v-btn>
        <v-btn
          small
          text
          :outlined="scope === 'waitListOnly'"
          @click="setScope('waitListOnly')"
        >
          <span caption text-lowercase>Warteliste</span>
        </v-btn>
        <NewPatient></NewPatient>
      </v-card-subtitle>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suchen"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>
      <v-skeleton-loader v-if="loading" type="table-tbody"></v-skeleton-loader>
      <v-data-table
        v-else
        dense
        :headers="scope == 'waitListOnly' ? headersWaitlist : headers"
        :items="scopedPatients"
        :search="search"
        :items-per-page="20"
        class="elevation-1"
        @click:row="rowSelected"
      ></v-data-table>
    </v-card>
  </div>
</template>


<script>
import NewPatient from "../components/AddPatient";
export default {
  data() {
    return {
      loading: true,
      tab: null,
      items: [
        { tab: "Alle", content: "all" },
        { tab: "Aktiv", content: "active" },
        { tab: "Warteliste", content: "waitlist" },
      ],
      search: "",
      headers: [
        {
          text: "Nachname",
          align: "start",
          sortable: true,
          value: "lastName",
        },
        {
          text: "Vorname",
          align: "start",
          sortable: true,
          value: "firstName",
        },
        //{ text: "Geburtsdatum", value: "dateOfBirth" },
        { text: "Telefon", value: "phoneNumbers[0].number" },
        //{ text: "Ort", value: "location" },
      ],
      headersWaitlist: [
        {
          text: "Nachname",
          align: "start",
          sortable: true,
          value: "lastName",
        },
        {
          text: "Vorname",
          align: "start",
          sortable: true,
          value: "firstName",
        },
        //{ text: "Geburtsdatum", value: "dateOfBirth" },
        { text: "Telefon", value: "phoneNumbers[0].number" },
        //{ text: "Ort", value: "location" },
        { text: "Warteliste seit", value: "waitlistSince" },
      ],
      patients: [],

      exampleList: ["Mo08:00", "Mo09:00"],
      scope: "all",
    };
  },
  computed: {
    scopedPatients() {
      if (this.scope == "waitListOnly") {
        return this.patients.filter((item) => item.status == "waitlist");
      } else if (this.scope == "activeOnly") {
        return this.patients.filter((item) => item.status == "active");
      } else {
        return this.patients;
      }
    },
  },
  components: {
    NewPatient,
  },
  methods: {
    getPatients(tab) {
      console.log(tab);
      return this.patients;
    },
    setScope(scope) {
      this.scope = scope;
    },
    rowSelected(row) {
      const id = row._id;
      const name = row.lastName + ", " + row.firstName;
      this.$router.push({ name: "Patient", params: { id, name } });
    },
  },
  async mounted() {
    console.log("Fetch patients from backend");
    const response = await this.$http.get("patient/");
    this.patients = response.data;
    this.loading = false;
  },
};
</script>
