<template>
  <content>

    <v-row
      ><v-col
        ><v-card class="pa-2" outlined tile>
          
          <v-tabs show-arrows color="blue-grey darken-3">
            <v-tab v-for="tab in tabs" :key="tab.tab">
              <v-badge
                v-if="tab.badgeContent"
                :color="tab.badgeColor"
                :content="tab.badgeContent"
                >{{ tab.tab }}
              </v-badge>
              <div v-else>{{ tab.tab }}</div>
            </v-tab>

            <v-tab-item v-for="tab in tabs" :key="tab.tab">
              <v-card flat>
                <v-card-text>
                  <component
                    v-bind:is="tab.component"
                    :availabilityList="patient.availability"
                    :patient="patient"
                    :readonly="true"
                  ></component
                ></v-card-text> </v-card
            ></v-tab-item>
          </v-tabs> </v-card></v-col
    ></v-row>
  </content>
</template>

<script>
import PatientMasterdata from "../components/PatientMasterdata";
import Availability from "../components/availability";
import PrescriptionList from "../components/PrescriptionList"
import Docu from "../components/Documentation"

export default {
  data() {
    return {
      patient: { availability: [] },
      tabs: [
        { tab: "Stammdaten", component: "PatientMasterdata"},
        { tab: "Termine", component: "" },
        { tab: "Verordnungen", component: "PrescriptionList" },
        { tab: "Dokumentation", component: "Docu" },
        { tab: "Notizen", component: "" },
        { tab: "Verfügbarkeit", component: "Availability" },
        {
          tab: "Kommunikation",
          component: "",
          badgeColor: "green",
          badgeContent: "2",
        },
      ],
      telephone: [
        { type: "Privat", value: "07243 3507190" },
        { type: "Mobil", value: "07243 3507190" },
        { type: "Mutter", value: "07243 3507190" },
        { type: "Geschäft", value: "07243 3507190" },
      ],
      items: [
        { text: "07243 / 3507190 (Privat)", icon: "mdi-phone-dial" },
        { text: "Audience", icon: "" },
        { text: "Conversions", icon: "" },
      ],
    };
  },
  props: {
    id: 0,
  },
  components: {
    PatientMasterdata,
    Availability,
    PrescriptionList,
    Docu,
  },
  async mounted() {
    console.log("Fetch patient from backend");
    const response = await this.$http.get("patient/" + this.id);
    this.patient = response.data;
    this.patient.availability = [];
    this.$mixpanel.track("sign up");
  },
};
</script>
