<template>
  <v-card>
    <v-card-subtitle right> Neuer Mitarbeiter </v-card-subtitle>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Suchen"
        single-line
        hide-details
      >
      </v-text-field>
    </v-card-title>
    <v-data-table
      dense
      :headers="headers"
      :items="userlist"
      :search="search"
      :items-per-page="20"
      class="elevation-1"
      @click:row="rowSelected"
    ></v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      scope: "all",
      userlist: [],
      headers: [
        {
          text: "Nachname",
          align: "start",
          sortable: true,
          value: "last_name",
        },
        {
          text: "Vorname",
          align: "start",
          sortable: true,
          value: "first_name",
        },
        {
          text: "Kürzel",
          align: "start",
          sortable: true,
          value: "shortcut",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
        },
      ],
      pricelists: [],
    };
  },
  components: {},
  methods: {
    rowSelected(row) {
      //  const id = row._id;
      //  const name = row.tarifName + " vom " + row.validFrom;
      //  this.$router.push({ name: "Preisliste", params: { id, name } });
    },
  },
  async mounted() {
    console.log("Fetch pricelists from backend");
    const response = await this.$http.get("auth/user/");
    this.userlist = response.data;
    this.loading = false;
  },
};
</script>

<style>
</style>