<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card tile>
      <v-card-title>Dokumentation</v-card-title>
      <v-card-text>
        <v-form model="isValid">
          <v-select
            v-model="category"
            :items="categories"
            label="Kategorie"
            dense
          ></v-select>
          <v-tiptap
            v-model="content"
            value=""
            :toolbar="toolbar"
            placeholder="Dokumentation"
            locale="de"
          />
          <br />
          <!--<v-textarea label="Dokumentation" v-model="content"></v-textarea>-->
          <v-select
            v-model="appointmentStatus"
            :items="appointmentStatusList"
            label="Terminstatus"
            :value="appointmentStatusList[0]"
            dense
          ></v-select>
          <v-text-field
            v-model="date"
            label="Datum"
            type="date"
            required
          ></v-text-field>
        </v-form>
        <v-btn @click="saveAndNew" :disabled="!isValid" tile outlined
          ><v-icon left>mdi-folder-plus-outline</v-icon>Weiterer Eintrag</v-btn
        ><br />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="save" :disabled="!isValid" tile
          ><v-icon left>mdi-folder-check-outline</v-icon> Speichern</v-btn
        >
        <v-btn @click="dialog = false" :disabled="!isValid" tile
          ><v-icon left>mdi-folder-cancel-outline</v-icon>Abbrechen</v-btn
        >
      </v-card-actions>
      <v-card-text>
        <br />
        <v-divider></v-divider>
        <br />
        <Docu :patient="activePatient" :unsavedDocuItems="newDocuItems" />
      </v-card-text>


    </v-card>
  </v-dialog>
</template>

<script>
import "@js-joda/timezone";
import { DateTimeFormatter, LocalDateTime } from "@js-joda/core";
import { Locale } from "@js-joda/locale_de"; // Get `Locale` from the prebuilt package of your choice
var nativeJs = require("@js-joda/core").nativeJs;
import Docu from "../components/Documentation";
export default {
  data() {
    return {
      /*0:"bold"
1:"italic"
2:"underline"
3:"strike"
4:"color"
5:"|"
6:"headings"
7:"|"
8:"left"
9:"center"
10:"right"
11:"justify"
12:"|"
13:"bulletList"
14:"orderedList"
15:"|"
16:"link"
17:"image"
18:"video"
19:"emoji"
20:"|"
21:"clear"

      */
      toolbar: [
        "bold",
        "italic",
        "underline",
        "|",
        "bulletList",
        "orderedList",
        "|",
        "emoji",
        ">",
        "clear",
      ],
      //Anamnese, Diagnostik / Befund, Diagnose, Therapie, Therapieziel

      categories: [
        "Therapie",
        "Anamnese",
        "Therapieziel",
        "Diagnose",
        "Diagnostik / Befund",
      ],
      appointmentStatusList: ["Wahrgenommen", "Abgesagt", "Nicht ändern"],
      appointmentStatus: "Wahrgenommen",
      dialog: false,
      category: null,
      date: null,
      content: "",
      isValid: true,
      newDocuItem: null,
      newDocuItems: [],
      test: "f",
      activePatient: null,
    };
  },
  computed: {},
  props: {
    show: false,
    appointment: null,
  },
  components: {
    Docu,
  },
  watch: {
    appointment: {
      handler(val) {
        const d = LocalDateTime.from(nativeJs(new Date(val.start)));

        const formatter = DateTimeFormatter.ofPattern("yyyy-MM-dd").withLocale(
          Locale.GERMANY
        );
        console.log("Appointment FOcus changed - converting date");

        console.log(d.format(formatter));
        this.date = d.format(formatter);
        this.activePatient = val.treatment[0].prescription.patient;
      },
    },
    dialog: {
      handler(val) {
        if (val == false) {
          this.resetFormAndData();
        }
        this.$emit("update:show", val);
      },
    },
    show: {
      handler(val) {
        this.dialog = this.show;
      },
    },
  },
  mounted() {
    this.dialog = this.show;
    console.log("the component (new Docu) is now mounted.");
  },
  methods: {
    resetFormAndData() {
      this.newDocuItems = [];
      this.newDocuItem = null;
      this.content = "";
      this.category = "";
      //this.date = null;
    },
    saveAndNew() {
      if (!this.category) return false;
      if (!this.newDocuItem) {
        console.log("Creating the docu item");
        this.test = "g";
        const date = new Date(this.date).toISOString();
        this.newDocuItem = {
          weekday: "Donnerstag",
          date: date,
          patient: this.appointment.treatment[0].prescription.patient._id,
          user: this.appointment.user._id,
          appointment: this.appointment._id,
          docuElements: [
            {
              //_id: "dlksajf l",
              category: this.category,
              content: this.content,
            },
          ],
        };
        this.newDocuItems.push(this.newDocuItem);
      } else {
        this.newDocuItem.docuElements.push({
          //_id: "dlksajf l",
          category: this.category,
          content: this.content,
        });
      }
      this.category = "";
      this.content = "";
      //this.test = "sakdlj";
    },
    async save() {
      console.log("Save docu");
      /*tenant: 'String',
      important: 'Boolean', 
      date: 'Date',
      docuItem: [DocuItemSchema],
      patient: {
        type: mongoose.Schema.Types.ObjectId,
        ref: "Patient",
      },
      user: {
        type: mongoose.Schema.Types.ObjectId,
        ref: "User",
      },
      appointment: {
        type: mongoose.Schema.Types.ObjectId,
        ref: "Appointment",
      }*/
      this.saveAndNew();
      const body = this.newDocuItem;
      try {
        const response = await this.$http.post("docu/", body);
        console.log("... saved");
      } catch (error) {
        console.log("... error when saving Docu");
      }
      this.dialog = false;
    },
  },
};
</script>