<template>
<div>
 <v-app-bar app dense elevate-on-scroll clipped-left class="blue-grey">
   <v-spacer></v-spacer>
    <RegisterForm />
        
   </v-app-bar>
</div>
</template>

<script>
import RegisterForm from "./RegisterForm"
export default {
  components: {
    RegisterForm,
  },
}
</script>


