<template>
  <div>
    <v-navigation-drawer
      app
      v-model="drawer"
      clipped
      class="blue-grey lighten-4"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h4"> Apraxas </v-list-item-title>
          <v-list-item-subtitle> subtext </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav v-if="mode == 'admin'">
        <v-list-item
          @click="(mode = 'default'), $router.push({ name: 'Home' })"
        >
          <v-list-item-icon>
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>zurück zum Programm</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">Einstellungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="item in adminLinks"
          :key="item.text"
          router
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list nav v-else-if="mode == 'masterdata'">
        <v-list-item
          @click="(mode = 'default'), $router.push({ name: 'Home' })"
        >
          <v-list-item-icon>
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>zurück zum Programm</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">Stammdaten</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="item in masterdataLinks"
          :key="item.text"
          router
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list nav v-else>
        <v-list-item
          v-for="item in links"
          :key="item.text"
          router
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dense elevate-on-scroll clipped-left class="blue-grey">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <BreadCrumb />
      <v-spacer></v-spacer>
      <div>
        <v-btn icon @click="mode = 'masterdata'" plain>
          <v-icon color="black">mdi-database</v-icon>
        </v-btn>
        <v-btn icon @click="mode = 'admin'" plain>
          <v-icon color="black">mdi-tools</v-icon>
        </v-btn>
        <v-btn icon @click="logoutUser" plain>
          <v-icon color="black">mdi-exit-to-app</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import SetupDialog from "./SetupDialog";
import BreadCrumb from "./BreadCrumb";
export default {
  components: {
    SetupDialog,
    BreadCrumb,
  },
  data() {
    return {
      drawer: true,
      adminMode: false,
      masterdataMode: false,
      mode: "default",
      linkGroups: [{ text: "Group1" }, { text: "Group2" }],
      adminLinks: [
        {
          icon: "mdi-domain",
          text: "Praxis",
          route: "/cc",
        },
        {
          icon: "mdi-account",
          text: "Mitarbeiter",
          route: "/user",
        },
        {
          icon: "mdi-format-list-text",
          text: "Preislisten",
          route: "/pricelist",
        },
        {
          icon: "mdi-cash-register",
          text: "Krankenkassen",
          route: "/xx",
        },
      ],
      masterdataLinks: [
        {
          icon: "mdi-stethoscope",
          text: "Ärzte",
          route: "/cc",
        },
        {
          icon: "mdi-domain",
          text: "Betriebssätten",
          route: "/cc",
        },
        {
          icon: "mdi-file-sign",
          text: "Leistungen",
          route: "/xx",
        },
        {
          icon: "mdi-file-document-multiple-outline",
          text: "Diagnosegruppen",
          route: "/xx",
        },
        {
          icon: "mdi-sort-numeric-descending",
          text: "IDC 10",
          route: "/xx",
        },
      ],
      links: [
        { icon: "mdi-home", text: "Übersicht", route: "/" },
        {
          icon: "mdi-calendar-outline",
          text: "Terminkalender",
          route: "/calendar",
        },
        { icon: "mdi-human-queue", text: "Patienten", route: "/patient" },
        {
          icon: "mdi-clipboard-multiple-outline",
          text: "Verordnungen",
          route: "/prescription",
        },
        {
          icon: "mdi-clipboard-text-clock-outline",
          text: "Warteliste",
          route: "/waitlist",
        },
        {
          icon: "mdi-cash-multiple",
          text: "Abrechnung",
          route: "/invoice",
        },
      ],
    };
  },
  methods: {
    logoutUser: function () {
      this.$store.commit("user/logoutMutation");
    },
  },
};
</script>
