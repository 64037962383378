<template>
  <v-footer dark padless app>
    <v-card class="flex" flat tile>
      <v-card-text class="blue-grey py-2 white--text text-left">
        <v-icon small>mdi-domain</v-icon> Logopädie und Neurofeedback Kathrin
        Mai | <v-icon small>mdi-clipboard-account</v-icon> {{ userName }} |
        <v-icon small>mdi-database-outline</v-icon> Apraxas v0.1
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
  computed: {
    loggedIn: function () {
      return this.$store.state.user.status.loggedIn;
    },
    userName: function () {
      return this.$store.getters["user/getUserName"];
    },
  },
};
</script>