<template>
<div>
  
   <v-skeleton-loader
          v-if="loading"
          type="table-tbody"
        ></v-skeleton-loader>

   <v-data-table v-else
        dense
        :headers="headers"
        :items="prescriptions"
        :search="search"
        :items-per-page="20"
        class="elevation-1"
        @click:row="rowSelected"
      >
          <template v-slot:[`item.appointments`]="{ item }">
      <v-chip
        :color="getColor(item.appointments)"
        dark
      >
        {{ item.appointments }}
      </v-chip>
      </template>

      <template v-slot:[`item.additionalPayment`]="{ item }">

      <v-icon :color="getPaymentColor(item.additionalPayment)"> {{getPaymentIcon(item.additionalPayment)}} </v-icon>
      </template>
      </v-data-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      //search: "",
      headers: [
        {
          text: "Datum",
          sortable: true,
          value: "date",
        },
        {
          text: "Termine",
          sortable: true,
          value: "appointments",
        },
        {
          text: "Abrechnung",
          sortable: true,
          value: "invoice",
        },
        {
          text: "Zuzahung",
          sortable: true,
          value: "additionalPayment",
        },
        //{ text: "Geburtsdatum", value: "dateOfBirth" },
      ],
      prescriptions: [
        {"_id": "099", "patient": "Mai, Thorsten", "date" : "01.03.2022", "appointments": "3/10", "invoice": "---", "additionalPayment": "offen"},
        {"_id": "098", "patient": "Mai, Thorsten", "date" : "13.01.2022", "appointments": "10/10", "invoice": "erledigt", "additionalPayment": "waiting"},
        {"_id": "097", "patient": "Mai, Thorsten", "date" : "23.11.2021", "appointments": "10/10", "invoice": "erledigt", "additionalPayment": "gezahlt"},

      ]
    }
  },
  methods: {
      getColor (appointments) {
        if (appointments == "10/10") return 'grey'
        else return 'green'
      },
      getPaymentColor (status) {
        if (status == "gezahlt") return 'green'
        else if (status == "waiting") return 'yellow'
        else return 'red'
      },
      getPaymentIcon (status) {
        if (status == "gezahlt") return 'mdi-cash-check'
        else if (status == "waiting") return 'mdi-cash-fast'
        else return 'mdi-cash-remove'
      },
      rowSelected(row) {
      const id = row._id;
      if (this.patient) {
        const patId = this.patient._id;
        const patName = this.patient.lastName + ", " + this.patient.firstName
        this.$router.push({ name: "Verordnung", params: { id, patId, patName } });
      } else {
        this.$router.push({ name: "Verordnung", params: { id } });
      }
      //const name = row.lastName + ", " + row.firstName
      //this.$router.push({ name: "Verordnung", params: { id, patId, patName } });
      },
    },
    props: {
      patient: {}
    },
    async mounted () {
      var patientId = null;
      if (!this.patient) {
      this.headers.splice(0, 0, {
          text: "Patient",
          sortable: true,
          value: "patient.fullName",
        });
        //patientId = this.patient._id;
      } else {
        patientId = this.patient._id;
      }
      //query prescriptions from backend
    console.log("Fetch prescriptions from backend");
    
    const response = await this.$http.get("prescription/", { params: { patient: patientId } });
    this.prescriptions = response.data;
    this.loading = false;
    }

}
</script>

<style>

</style>