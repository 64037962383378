<template>
    <v-card>
      <v-card-subtitle right>
        <v-btn small text :outlined="scope === 'all'" @click="setScope('all')">
          <span caption text-lowercase>Alle</span>
        </v-btn>
        <v-btn
          small
          text
          :outlined="scope === 'activeOnly'"
          @click="setScope('activeOnly')"
        >
          <span caption text-lowercase>XXX</span>
        </v-btn>
        <v-btn
          small
          text
          :outlined="scope === 'waitListOnly'"
          @click="setScope('waitListOnly')"
        >
          <span caption text-lowercase>YYY</span>
        </v-btn>
      </v-card-subtitle>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suchen"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>

  <PrescriptionList/>
    </v-card>
</template>

<script>
import PrescriptionList from '../components/PrescriptionList'
export default {
  components: {
    PrescriptionList
  }
}
</script>

<style>

</style>