<template>
  <v-stepper v-model="currentStep" vertical flat non-inear>
    <v-stepper-step step="1" editable>
      9:00 - 9:45
      <small>Mai, Thorsten</small>
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card color="grey lighten-1" mx-2 max-width="374">
        <v-card-title>kjl fksadl </v-card-title>
        <v-card-text
          ><p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi nobis
            neque nostrum accusamus repudiandae consequuntur veniam, recusandae
            quis facilis ut suscipit eaque possimus praesentium pariatur fugit
            eveniet earum inventore sunt?
          </p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis,
          similique quae harum molestiae asperiores sunt? Aperiam mollitia
          dolores a distinctio inventore voluptates odit eos, reiciendis
          similique ad? Maiores, at atque.</v-card-text
        >
        <v-card-actions
          ><v-btn color="primary" @click="e6 = 2"> Continue </v-btn>
          <v-btn text> Cancel </v-btn></v-card-actions
        >
      </v-card>
    </v-stepper-content>

    <v-stepper-step step="2" editable>
      10:00 - 10:30
      <small>Teamsitzung</small>
    </v-stepper-step>

    <v-stepper-content step="2" editable>
      <v-card color="grey lighten-1" class="mb-12" height="200px"></v-card>
      <v-btn color="primary"> Continue </v-btn>
      <v-btn text> Cancel </v-btn>
    </v-stepper-content>

    <v-stepper-step :complete="e6 > 3" step="3" editable>
      10:00 - 10:30
      <small>Teamsitzung</small>
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-card color="grey lighten-1" class="mb-12" height="200px"></v-card>
      <v-btn color="primary" @click="e6 = 4"> Continue </v-btn>
      <v-btn text> Cancel </v-btn>
    </v-stepper-content>

    <v-stepper-step step="4" editable>
      10:00 - 10:30 <small>Teamsitzung</small>
    </v-stepper-step>
    <v-stepper-content step="4">
      <v-card color="grey lighten-1" class="mb-12" height="200px"></v-card>
      <v-btn color="primary" @click="e6 = 1"> Continue </v-btn>
      <v-btn text> Cancel </v-btn>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
export default {
  data() {
    return {
      e6: 1,
      currentStep: 0,
    };
  },
};
</script>
