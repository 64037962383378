const userToken = localStorage.getItem('userToken');
console.log("Token: " + userToken)
var initialState = { status: {loggedIn: false, loaded: false}, user: {} }

/*if (userToken) {
  //query user info
  //const response = this.$http.post("auth/me/");
  //console.log("Found token, trying to query /me with my token")
  //console.log(response.data)

  var user = {
      name: 'Thorsten Mai',
    _id: "dfas897sdf0987"
  }

  initialState.status.loggedIn = true;
  //initialState.user = user;
} */



export default {
  namespaced: true,
  /*state: {
    status: {
      loggedIn: true,
    },
    loggedIn: localStorage.getItem('user.token') ||  '',
    user: {
      name: 'Thorsten Mai',
      _id: "dfas897sdf0987",
      token: localStorage.getItem('user.token') ||  ''
    }
  },*/
  state: initialState,
  getters: {
    getUserInitials (state) {
      //const splittedName = state.user.name.split(' ')
      //return splittedName[0].charAt(0) + splittedName[1].charAt(0)
      return "TM"
    },
    getUserName(state) {
      //return "Test User"
      return state.user.last_name + ", " + state.user.first_name
    }
  },
  mutations: {
    changeName (state, newName) {
      state.user.name = newName
    },
    loginMutation (state, user) {
      state.status = { loggedIn: true, loaded: true };
      state.user = user
    },
    logoutMutation (state) {
      state.status = {};
      state.user = null
      localStorage.removeItem('userToken');
    }
  },
  actions: {
    async changeName (context, newName) {
      await setTimeout(() => {
        context.commit('changeName', newName)
      }, 3000)
    },
    async loginAction (context, props) {
      // TODO: verify login

      // TODO: load user data in state.user
      console.log("Test")
      
      context.commit('loginMutation', props.user)


    }
  }
}
