<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card tile class="ma-4">
          <v-card-title class="text-overline">Apraxas</v-card-title>
          
          <v-card-text>
            
            <v-form>
              <v-text-field
                prepend-icon="mdi-at"
                name="login"
                v-model="username"
                label="E-Mail"
                type="text"
                
              ></v-text-field>
              <v-text-field
                prepend-icon="mdi-lock"
                name="login"
                v-model="password"
                label="Passwort"
                type="password"
                
              ></v-text-field>
            </v-form>
            <v-alert v-if="wrongCredentials"  outlined type="warning">
              Anmeldedaten ungültig.
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="loginUser" tile outlined>Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "LoginForm",

  data: () => ({
    wrongCredentials: false,
    username: "",
    password: "",
    tenant: "",
  }),
  computed: {
    loggedIn: function () {
      return this.$store.state.user.loggedIn;
    },
  },
  methods: {
    loginUser: async function () {
      console.log(this.username);
      console.log(this.password);
      const body = {
        email: this.username,
        password: this.password,
      };
      try {
        const response = await this.$http.post("auth/login/", body);
        console.log(response);
        localStorage.setItem("userToken", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        this.$store.dispatch("user/loginAction", {
          user: response.data,
        });
      } catch (error) {
        console.log(error);
        this.wrongCredentials = true;
        //console.log(error.response.status);
      }

      //this.$store.dispatch('user/loginAction', {username: this.username, password: this.password})
    },
  },
};
</script>