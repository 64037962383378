<template>
<v-card flat pa-3>
      <v-card-title>{{ item.firstName }} {{ item.lastName }}</v-card-title>
      <v-card-text>
        <v-card-title></v-card-title>
        <v-layout row wrap>
         <v-flex xs6 md3 pa-1>
          <div class="caption grey--text">Aufgenommen am</div>
          <div>01.01.2022 (25 Tage)</div>
         </v-flex>
         
        <v-flex xs6 md3 pa-1>
          <div class="caption grey--text">Diagnose</div>
          <div>Dies ist die Diagnose</div>
         </v-flex>
         <v-flex xs12 md6 pa-1>
          <div class="caption grey--text">Kommentar</div>
          <div>Lorem ipsum dolor sit amet consectetur adipisicing elit.</div>
         </v-flex>
                  
        <v-flex xs12 md6 pa-1>
          <div class="caption grey--text">Verfügbarkeit</div>
          <div><Availability :availabilityList="item.availability" readonly></Availability></div>
         </v-flex>
         
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn small>
          <v-icon left small>mdi-magnify-scan</v-icon>
          Details
          </v-btn>
        <v-btn small>
          <v-icon left small>mdi-delete</v-icon>  
          löschen
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
    </v-card>
</template>

<script>
  import Availability from './availability'
  export default {
    props: {
      item: Object
    },
    components: {
      Availability
    }
  }
</script>