<template>
  <div class="home">
    <v-container>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-title>Terminübersicht</v-card-title>
            <v-card-text> <AppointmentList /></v-card-text>
          </v-card>
        </v-col>
        <v-col
          ><v-card outlined height="100%">
            <v-card-title>Notizen</v-card-title>
            <v-card-text> dd</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AppointmentList from "../components/AppointmentList";
export default {
  name: "Home",
  components: {
    AppointmentList,
  },
};
</script>
