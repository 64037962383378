<template>
    
<table width="85%" class="presc" >
  <tr>
    <td class="pa-2 light-blue lighten-4" >
      <table width = "100%" class="presc_i white" cellspacing="0">
        
        <tr>
          <td class="border_red_tbl"><div class="red--text caption">Zuzahl-<br>frei</div></td>
          <td rowspan ="2" colspan="3" class="border_red_tlr"><div ><div class="red--text caption">Krankenkasse bzw. Kostenträger</div>{{prescription.insurance.Name }}</div></td>
          <td rowspan = "9" class = "pa-2 pr-4 presc light-blue lighten-4">
            <div class="red--text headline text-right">Heilmittelverordnung 13 </div>
            <br>
            <div class="red--text">
            <br>
            <v-icon color="red"> mdi-checkbox-blank-outline </v-icon>Podologische Therapie<br>
            <v-icon color="red"> mdi-checkbox-blank-outline </v-icon>Physiotherapie<br>
            <v-icon color="red"> mdi-checkbox-outline </v-icon>Stimm-, Sprech-, Sprach- und Schlucktherapie<br>
            <v-icon color="red"> mdi-checkbox-blank-outline </v-icon>Ergotherapie<br>
            <v-icon color="red"> mdi-checkbox-blank-outline </v-icon>Ernährungstherapie 
            </div>
          </td>   
        </tr>     
        <tr><td class="light-blue lighten-4"><div class="caption">&nbsp;</div></td></tr>
        <tr>
          <td class="border_red_tbl"><div class="red--text caption">Zuzahl-<br>pflicht</div></td>
          <td  rowspan="4" colspan="3" class="border_red_tlr"><div class="red--text caption">Versicherter</div>{{ prescription.patient.fullName }}<br>{{ prescription.patient.street }}<br>{{ prescription.patient.zipCode }} {{ prescription.patient.city }}</td>
        </tr>
        <tr><td class="light-blue lighten-4"><div class="caption">&nbsp;</div></td></tr>
        <td class="border_red_tbl"><div class="red--text caption">Unfall-<br>folgen</div></td>
        <tr><td class="light-blue lighten-4"><div class="caption">&nbsp;</div></td></tr>

        <tr>
          <td class="border_red_tbl"><div class="red--text caption">BVG</div></td>
          <td rowspan="2" width="25%"><div class="border_red_tl_rhalf"><div class="red--text caption">Kostenträgerkennung</div>{{prescription.insurance.IK }}</div></td>
          <td rowspan="2" width="25%"><div class="border_red_t_rhalf"><div class="red--text caption">Versicherten-Nr.</div> 1432124412</div></td>
          <td rowspan="2" width="15%"><div class="border_red_tr"><div class="red--text caption">Status</div> 01</div></td>
        </tr>
        <tr><td class="light-blue lighten-4"><div class="caption">&nbsp;</div></td></tr>
        <tr>
          <td class="light-blue lighten-4"><div class="caption">&nbsp;</div></td>
          <td><div class="border_red_tbl_rhalf"><div class="red--text caption">Betriebstätten-Nr.</div> 729847123987<br>&nbsp;</div></td>
          <td><div class="border_red_tb_rhalf"><div class="red--text caption">Arzt-Nr.</div>{{ prescription.doctor.LANR }} <br>({{ prescription.doctor.title }} {{ prescription.doctor.fullName }})</div></td>
          <td><div class="border_red_tbr"><div class="red--text caption">Datum</div> {{ prescription.date }}<br>&nbsp;</div></td>
        </tr>
        <tr>
          <td class="light-blue lighten-4">&nbsp;</td>
    <td colspan="4" class="padding_tb light-blue lighten-4"><div class="red--text"><b>Behandlungsrelevante Diagnose(n)</b><br>ICD-10-Code</div></td>
  </tr>
  <tr>
    <td class="light-blue lighten-4">&nbsp;</td>
    <td colspan="4">
      <table width="100%" cellspacing="0">
        <tr>
          <td width="20%" class="border_red_b" colspan="2">{{ prescription.icd10_1 }}&nbsp;</td>
          <td width="5%" rowspan="2" class="light-blue lighten-4"></td>
          <td rowspan="2" colspan="4">Sprachentwicklungsstörung<br> &nbsp;</td>
        </tr>
        <tr>
          <td class="border_red_b" colspan="2">{{ prescription.icd10_2 }}&nbsp;</td>
        </tr>
        <tr>
          <td class="light-blue lighten-4" colspan="2">&nbsp;</td>
          <td class="light-blue lighten-4"></td>
          <td class="light-blue lighten-4" colspan="4"></td>
        </tr>
        <tr>
          <td width="10%" class="light-blue lighten-4 red--text"><b>Diagnose-<br>gruppe</b></td>
          <td width="10%" class="border_red_b">SP1</td>
          <td class="light-blue lighten-4"></td>
          <td width="20%" class="light-blue lighten-4 red--text"><b>Leitsymptomatik</b><br>gemäß Heilmittelkatalog</td>
          <td class="light-blue lighten-4 red--text">
            <v-icon color="red" large> mdi-checkbox-blank-outline </v-icon> a &nbsp;&nbsp;
            <v-icon color="red" large> mdi-checkbox-blank-outline </v-icon> b &nbsp;&nbsp;
            <v-icon color="red" large> mdi-checkbox-blank-outline </v-icon> c
            </td>
          <td class="light-blue lighten-4 red--text align_right">patientenindividuelle<br>Leitsymptomatik</td>
          <td class="light-blue lighten-4 red--text">&nbsp;&nbsp;<v-icon color="red" large> mdi-checkbox-blank-outline </v-icon></td>

        </tr>
        <tr>
          <td colspan="7" class="padding_t light-blue lighten-4 red--text">Leitsymptomatik <b>(patientenindividuelle Leitsymptomatik als Freitext angeben)</b></td>
        </tr>

        
        <tr>
          <td colspan="7" class="padding_t"><br><br><br></td>
        </tr>

      </table>  
    </td>
  </tr>
  <tr>
    <td class="light-blue lighten-4"></td>
    <td class="padding_t light-blue lighten-4 red--text" colspan="4"><b>Heilmittel nach Maßgabe des Kataloges</b></td>
  </tr>

  <tr>
    <td class="light-blue lighten-4"></td>
    <td colspan="4">
      <table width="100%" cellspacing="0">
        <tr>
          <td width="80%" class="light-blue lighten-4 red--text">Heilmittel</td>
          <td class="light-blue lighten-4 red--text">Behandlungseinheiten</td>
        </tr>
        <tr>
          <td width="80%" class="border_red_br">{{ prescription.service_1 }}</td>
          <td class="border_red_b">{{ prescription.service_1_units }}&nbsp;</td>
        </tr>
        <tr>
          <td class="border_red_br"></td>
          <td class="border_red_b">&nbsp;</td>
        </tr>
        <tr>
          <td class="border_red_br"></td>
          <td class="border_red_b">&nbsp;</td>
        </tr>
        <tr>
          <td width="80%" class="light-blue lighten-4 red--text">Ergänzendes Heilmittel</td>
          <td class="light-blue lighten-4 red--text"></td>
        </tr>
        <tr>
          <td width="80%" class="border_red_br"></td>
          <td class="border_red_b">&nbsp;</td>
        </tr>
      </table>
    </td>
  </tr>
  <tr>
    <td class="light-blue lighten-4"></td>
    <td  colspan="4">
      <table table width="100%" cellspacing="0">
        <tr><td colspan="5" class="light-blue lighten-4">&nbsp;</td></tr>
        <tr>
          <td class="light-blue lighten-4"></td>
          <td class="light-blue lighten-4 red--text">
            <v-icon color="red" large v-if="prescription.report"> mdi-checkbox-outline </v-icon> 
            <v-icon color="red" large v-else> mdi-checkbox-blank-outline </v-icon>
            <b> Therapiebericht</b></td>
          
          <td class="light-blue lighten-4 red--text">
            <b>Hausbesuch</b>&nbsp;&nbsp; 
              <v-icon color="red" large v-if="prescription.homeVisit"> mdi-checkbox-outline </v-icon>
              <v-icon color="red" large v-else> mdi-checkbox-blank-outline </v-icon> 
              ja 
              <v-icon color="red" large v-if="prescription.homeVisit"> mdi-checkbox-blank-outline </v-icon>
              <v-icon color="red" large v-else> mdi-checkbox-outline </v-icon> 
              nein
            </td>
          <td class="light-blue lighten-4 red--text align_right"><b>Therapiefrequenz&nbsp;&nbsp;</b></td>
          <td class="border_red_b" width="15%"></td>
        </tr>
        <tr>
          <td class="light-blue lighten-4"></td>
          <td class="light-blue lighten-4 red--text"><v-icon color="red" large> mdi-checkbox-blank-outline </v-icon> <b>Dringlicher Behandlungsbedarf</b> innerhalb 14 Tage</td>
          <td colspan="3" class="light-blue lighten-4 red--text"></td>
        </tr>
      </table>
    </td>
  </tr>
    <tr>
    <td class="light-blue lighten-4"></td>
    <td class="padding_t light-blue lighten-4 red--text" colspan="4"><b>ggf. Therapieziele / weitere med. Befunde und Hinweise</b></td>
  </tr>
  <tr>
    <td class="light-blue lighten-4"></td>
    <td class="" colspan="4"><br><br><br><br></td>
  </tr>
      </table>
    </td>
    
  </tr>
  
</table>
 
</template>

<script>

export default {
  props: {
    id: 0,
    patientId: 0,
    prescription: null,
  },
};
</script>

<style>
table.presc {
  padding: 0px;
  border-spacing: 0px;
  margin: 0px;
}

td.padding_t {
  padding-top: 5px;
  padding-bottom: 0px;
}

td.presc {
  
  vertical-align: top;
}

td.align_right {
  
  text-align: right;
}

td.td_red {
  border: 1px solid red;
  border-collapse: collapse;
}

div.border_red {
  padding-left: 5px;
  border: 1px solid red;
  border-collapse: collapse;
}

td.border_red_tlr {
  vertical-align: top;
  height: 100%;
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-left: 1px solid red;
  border-right: 1px solid red;
  border-collapse: collapse;
}


div.border_red_tlr {
  height: 100%;
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-left: 1px solid red;
  border-right: 1px solid red;
  border-collapse: collapse;
}

div.border_red_tb {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-collapse: collapse;
}

div.border_red_lr {
  padding-left: 5px;
  padding-bottom: 5px;
  border-left: 1px solid red;
  border-right: 1px solid red;
  border-collapse: collapse;
}

td.border_red_tbl {
  vertical-align: top;
  padding-left: 5px;
  
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-left: 1px solid red;
  border-collapse: collapse;

}

td.border_red_b {
  vertical-align: top;
  padding-left: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid red;
  border-collapse: collapse;

}

td.border_red_br {
  vertical-align: top;
  padding-left: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid red;
  border-right: 1px solid red;
  border-collapse: collapse;

}

div.border_red_tbl {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-left: 1px solid red;
  border-collapse: collapse;

}

div.border_red_tr {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-right: 1px solid red;
  border-collapse: collapse;

}

div.border_red_tbr {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-right: 1px solid red;
  border-collapse: collapse;
}

div.border_red_tl_rhalf {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-left: 1px solid red;

  position: relative;
}
div.border_red_tl_rhalf:after {
  position: absolute;
  border-right: 1px solid red;
  height: 40%;
  width: 100%;
  bottom: 0px;
  left: 0;
  content: "";
}

div.border_red_t_rhalf {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;


  position: relative;
}
div.border_red_t_rhalf:after {
  position: absolute;

  border-right: 1px solid red;
  height: 40%;
  width: 100%;
  bottom: 0px;
  left: 0;
  content: "";
}

div.border_red_tbl_rhalf {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  border-left: 1px solid red;
  position: relative;
}
div.border_red_tbl_rhalf:after {
  position: absolute;

  border-right: 1px solid red;
  height: 40%;
  width: 100%;
  bottom: 0px;
  left: 0;
  content: "";
}

div.border_red_tb_rhalf {
  padding-left: 5px;
  padding-bottom: 5px;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
  position: relative;
}
div.border_red_tb_rhalf:after {
  position: absolute;

  border-right: 1px solid red;
  height: 40%;
  width: 100%;
  bottom: 0px;
  left: 0;
  content: "";
}

td.border_green {
  border-bottom: 1px solid green;
  border-top: 1px solid green;
  border-left: 1px solid green;
  border-right: 1px solid green;
  border-collapse: collapse;
}

tr.tr_red {
  border: 1px solid red;
  border-collapse: collapse;
}
</style>